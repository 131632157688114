import langKeys from "../constants/lang/langKeys";

/**
 * Provide a key language from navigator or session storage
 *
 * @returns {string}
 */
const sessionLangInit = () => {
  // get key from sessionStorage
  const key = sessionStorage.getItem("langKey");

  if (!key) {
    /**@type {string} */
    var localLang = navigator.language || navigator.userLanguage;

    localLang = localLang.replace(/\W\w+/, "");

    // test lang key don't match system lang keys list and set the default lang
    if (langKeys.findIndex((k) => k.key === localLang) < 0) {
      sessionStorage.setItem("langKey", "en");
      return "en";
    }

    sessionStorage.setItem("langKey", localLang);
    return localLang;
  } else {
    return key;
  }
};

export default sessionLangInit;
