const link = {
  send: "envoyer",
  contactUs: "contactez-nous",
  faqs: "faqs",
  aboutUs: "a-propos-de-nous",
  resetPassword: "resetPassword",
  resetPasswords: "resetPasswords",
  login: "connexion",
  register: "inscription",
  transferInit: "transfert/init",
  connectMX: "connecterMX",
  conditionsTerms: "conditions-generales",
  policy: "politique",
  cookies: "cookies",
  share: "partager",
  career: "carriere",
  careerJobOffer: "offre-de-carriere",
  registerStep: {
    step0: "initialisation",
    step1: "information-de-localisation",
    step2: "informations-personnelles",
    step3: "informations-de-securite",
  },
};

export default link;
