/**
 * About us EN meta
 */
const meta = {
  keywords: "Money transfer, cameroon no fees",
  meta_description_default:
    "Send money for free with Transfergratis. Instant, fee-free, and secure transfers to Africa. Download our app now!",
  meta_description_register:
    "Sign up with Transfergratis and enjoy instant, fee-free international money transfers. Join us today for a seamless experience!",
  meta_description_login:
    "Log in to your Transfergratis account for secure and instant international money transfers. Fast, free, and easy access!",
  meta_description_secure:
    "Transfergratis is regulated and authorized to carry out money transfers by the Canadian federal government and the provincial government of Quebec.",
  meta_description_faqs:
    "Find answers to common questions about Transfergratis. Explore our FAQ for details on international money transfers, security, and more.",
  meta_description_contact_us:
    "Contact Transfergratis for any inquiries or support. We're here to assist you with secure and fast international money transfers.",
  meta_description_terms: "Terms and Conditions of Transfergratis",
  meta_description_privacy:
    "This Privacy Policy describes how Transfergratis uses, stores and manages certain informations that you, as a user of our services, provide to us.",
  meta_description_send:
    "Transfer money with Transfergratis for FREE to your loved ones in Cameroon in minutes.",
  meta_description_cookies:
    "This Privacy Policy describes how Transfergratis uses, stores and manages certain informations that you, as a user of our services, provide to us.",
  meta_description_transfer:
    "Transfer money with Transfergratis for FREE to your loved ones in {{country}} in minutes.",

  // Newww
  meta_description_career:
    "Join Transfergratis and be part of a growing team revolutionizing international money transfers. Explore exciting career opportunities today!",

  titlesecure: "Security at Transfergratis",

  titleterms: "Terms and Conditions | Transfergratis",
  titleprivacy: " Privacy Policy | Transfergratis",

  title: {
    default: "Transfergratis - Free and Instant Money Transfer Service",
    about_us: "About us",
    cid: "KYC",
    contact_us: "Contact us",
    dashboard: {
      profile: "Profile",
      security: "Security",
      transaction: "My transactions",
    },
    faqs: "Frequently Asked Questions",
    login:
      "Login: Log in to your Transfergratis account and send money to Cameroon and other countries without fees. Fast and secure!",
    register:
      "Register: Sign up on Transfergratis and start sending money online in minutes•! Create your account now to get started. Fast, Free, Secure!",
    resetPassword: "Reset password",
    transfer:
      "Send Money : Send money with Transfergratis for FREE to your loved ones in Cameroon in minutes.",
    career: "Career : We are looking for a new challenger",
    terms: "Terms and Conditions | Transfergratis",
    cookies: "Cookies | Transfergratis",
    policy: "Privacy Policy | Transfergratis",
    transferTitle: "Send Money ",
  },
};

export default meta;
