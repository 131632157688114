const register = {
  step0: {
    title: "Inscription",
    info: "Vous avez déjà un compte ?",
    link: "Connexion",
    info2: "Ou Inscrivez-vous avec",
    aceptText:
      "Je confirme que j'ai lu et accepté les conditions générales d'utilisation.",
  },
  step1: {
    title: "Comment vous contacter ?",
  },
  step2: {
    title: "Dites-nous plus sur vous",
  },
  step3: {
    title: "Choisissez votre mot de passe",
  },
};
export default register;
