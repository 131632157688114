/**
 * Transfer FR translations
 */
const transfer = {
  main: "Envoyer de l'argent",
  subMain:
    "Avec Transfergratis, transférez de l'argent GRATUITEMENT à vos proches au Cameroun et ailleurs en quelques minutes.",
  reasonsOfSending: "Raisons d'envois",
  bankName: "Nom de la banque",
  branchName: "Nom de la branche",
  agencyCode: "Code agence",
  amountTip: "Montant du pourboire",
  amountSendTotal: "Montant total",
  accountNumber: "Numero de compte pour le reseau {{network}}",
  accountNumberBank: "Numero de compte ",
  accountLastNumber: "Dernier numéro du compte",
  confirmAccountNumber: "Confirmer le numéro de compte",
  amountSend: "Montant envoyé",
  leaveTip: "Laisser un pourboire",

  forOther:
    "Cochez si vous faites cette transaction pour le compte de quelqu’un d’autre",
  coupon_notice:
    "Vous disposez d'un coupon de ${{amount}} qui sera appliquer sur votre prochain transfer",
  done: {
    firstTextP:
      "Transaction enregistrée, veuillez effectuer le virement INTERAC",
    firstTextP2:
      "Désolé, une erreur est survenue avec le paiement en ligne, veuillez svp faire le virement INTERAC manuellement.",
    payAmount: "Payez ${{amount}} CAD en ligne ici",
    proccedePaiement: "Procéder au paiement",
    boldText:
      "Si votre compte bancaire est Desjardins, ou si vous ne parvenez pas à payer en ligne en utilisant le bouton ci-dessus, envoyez ${{amount}} CAD via Interac à",
    boldText2: "Envoyez ${{amount}} CAD via Interac à l'adresse : ",
    boldText3: "Envoyez ${{amount}} CAD via Interac",
    secondTextP:
      "Notre adresse est configurée pour le dépôt automatique. Toutefois, si vous êtes invité à saisir une question et une réponse de sécurité, veuillez entrer:",
    needHelp: "Besoin d'aide?",
    lastTextP:
      "NB : Le nom du compte bancaire émetteur du virement Interac doit correspondre au nom de votre compte sur transfergratis.com. Sinon, la transaction ne peut pas être exécutée.",
    usa: {
      transfer_done_title: "Votre transfert a été initié.",
      transfer_done_detail:
        "Pour plus d'informations, rendez-vous sur votre tableau de bord.",
      transfer_fail_title: "Votre transfert ne peut pas être complété.",
      transfer_fail_detail:
        "Vous devez ajouter des fonds dans votre portefeuille.",
      to_wallet: "Aller au portefeuille",
    },
  },
  deposit: {
    title:
      "Pour approvisionner votre compte, sélectionnez votre compte, entrez le montant ci-dessous et cliquez sur le bouton PAYER.",
    conformation_thanks:
      "Merci pour votre dépôt. La confirmation ci-dessous vérifie que vous l'avez bien",
    payment_number: "Numéro de confirmation de paiement",
    payment_account: "Montant du paiement autorisé",
    date_authorized: "Date autorisée",
    "expected date": "Date de paiement prévue",
    add_bank: "Ajouter une banque",
    update_kyc:
      "Vous devez mettre à jour vos informations personnelles pour valider votre KYC et obtenir un wallet",
  },
  nameFormatError: "Accents et caractères spéciaux non autorisés",
  thirdParty: {
    address: "Adresse du tiers",
    relation: "Relation entre le tiers et vous",
    profesionOrNature: "Profession ou nature de l'activité du tiers",
    phone: "Téléphone du tiers",
    dob: "Date de naissance du tiers (si c'est un particulier)",
    number:
      "Numéro de constitution / d’enregistrement et la juridiction de constitution / enregistrement (si le tiers est une entité)",
    wave_redirect: "En cas de pertubation du reseau envoyer avec Wave",
  },
  dialog: {
    title: "Sélectionnez votre option de paiement",
    desJardinsMsg: "DesJardins Banque",
    otherBank: "Autres Banques",
    tipTitle: "Merci infiniment pour  votre pourboire de {{amount}} CAD! 🎉",
    tipBody:
      "Nous sommes profondément reconnaissants pour votre générosité. Comme vous le savez, Transfergratis est un service gratuit, donc votre contribution aidera directement à améliorer et à développer nos services pour vous offrir une expérience encore meilleure.",
    witTipAction: "Envoyer avec ${{amount}} de Pourboire",
    withoutTipAction: "Envoyer Sans Pourboire",
  },
};

export default transfer;
