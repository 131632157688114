const recusTrasaction = {
  title: "Recu de Transaction",
  subtitle: "Transaction effectuée avec succès",
  message:
    "Pour un transfert d'argent en ligne précis, rapide et sans frais, je recommande TransferGratis",
  destitnation: "Destinataire",
  numCompte: "Numero de compte",
  transId: "Transaction ID",
  method: "Methode",
  montant: "Montant transféré",
  montantTotalRecus: "Montant Recu",
  montantTotal: "Montant total",
  tipAmout: "Montant du Pourboire",
  frais: "TransferGratis Frais",
};

const ReleverTransaction = {
  title: "Relevé de transactions",
  copnum: "corporation number :",
  expediteur: "Nom Expediteur",
  montant: "Montant (CAD)",
  destinateur: "Nom Destinataire",
  numCompte: " Numero de Compte",
  motif: "Motif",
  tip: "Pourboire",
  total: "Total",
};

const pdf = {
  recusTrans: recusTrasaction,
  releveTrans: ReleverTransaction,
};

export default pdf;
