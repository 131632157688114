import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resources from "./constants/lang/resources";
import sessionLangInit from "./utils/sessionLangInit";

/**
 * This is used to config the application multi-language using i18n
 */

i18n.use(initReactI18next).init({
  lng: sessionLangInit(),
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
  resources,
});

export default i18n;
