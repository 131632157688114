/**
 * Kyc FR translations
 */
const kyc = {
    "title": "Vérification de votre identité",
    "title-2": "Nouvelle vérification de votre identité",
    "p1": "Pour des raisons de sécurité, nous devons valider votre identité.",
    "p1-2": "Pour des raisons de sécurité, nous devons de nouveau valider votre identité, car l'identification précédente a expiré.",
    "p2": "Pour cela, vous aurez besoin de: ",
    "l1": "Une Pièce d'identité avec photo;",
    "l2": "Un selfie de vous.",
    "needHelp": "Besoin d'aide?",
    "start": "Débuter"
}

export default kyc;