/**
 * About us EN translations
 */
const aboutUs = {
    "p1": "At Transfergratis, we believe that a money transfer is more than just a transaction. It is a child who will be able to go to school, it is a family that will have to eat, it is a patient whose life will be saved.",
    "p2": "All of this good is being done by those like you who have made the enormous sacrifice of leaving their families behind to study and work in another country to take care of those they love and to contribute to the development of their countries.",
    "p3": "It is for this reason that the entire Transfergratis team is committed to providing the best free money transfer services, so that we too can contribute to the good you do.",
    "p4": "Transfergratis is a company registered in Canada under company number 1301769-9, registered with FINTRAC under no. 21138662.",
}

export default aboutUs;