/**
 * global FR translations
 */
const globalLang = {
  address: "Adresse",
  account: "Account",
  amount: "Montant",
  answer: "Réponse",
  authorize: "autoriser",
  balance: "Solde",
  canada: "Canada",
  cancel: "Annuler",
  change: "Changer",
  city: "Ville",
  region: "Region",
  close: "Fermer",
  confirm: "Confirmer",
  contactUs: "Contactez-nous",
  cookies: "Politique sur les cookies et les technologies de suivi",
  country: "Pays",
  sendForm: "Envoyer le formulaire",
  dashboard: "Tableau de bord",
  description: "Description",
  message: "Votre message",
  deposit: "Dépôt",
  disbursable: "Déboursable",
  dob: "Date de naissance",
  edit: "Modifier",
  email: "Email",
  emailPlaceholder: "Entrer email Address",
  england: "Angleterre",
  error: "Erreur",
  errorLogin: "Combinaison email/mot de passe incorrect",
  exchangeRate: "Le taux de change actuel",
  forgetPwd: "Mot de passe oublié",
  fname: "Prénom",
  flname: "Nom Complet",
  friend: "Un connaissance",
  gender: "Genre",
  howHear: "Comment avez-vous entendu parler de nous?",
  invalid: "Invalide",
  help: "FAQ",
  history: "Historique",
  lname: "Nom",
  logout: "Deconnexion",
  locked: "Bloqué",
  key: "Clé",
  man: "Homme",
  mname: "Deuxième prénom",
  name: "Nom",
  of: "de",
  occupation: "Occupation",
  optional: "Facultatif",
  password: "Mot de passe",
  pay: "Payer",
  contry: "Pays",
  pending: "En cours",
  phone: "Téléphone",
  postalCode: "Code postal",
  previous: "Pécédent",
  privacy: "Confidentialité",
  province: "Province",
  question: "Question",
  recipient: "Destinataire",
  register: "S'inscrire",
  recipientReceive: "Le destinataire reçoit",
  security: "sécurité",
  save: "Enregistrer",
  send: "Envoyer gratuitement",
  select: "Choisir",
  share: "Partager",
  signIn: "Connexion",
  signUp: "Inscription",
  subject: "Sujet",
  successful: "Effectué",
  totalFee: "Frais totaux",
  withdrawalFee: "Frais de retait",
  town: "Ville",
  welcome: "Bienvenue",
  withdrawalMethod: "Méthode de retrait",
  woman: "Femme",
  youSend: "Vous envoyez",
  terms: "Termes et conditions",
  sponsorship: "Code parrainage",

  recus: "Recus",
  motivation: "Lettre de Motivation",
  submit: "Soumettre",
  postulate: "postuler",
  contract_type: "Type de contrat",
  work_type: "Type de travail",
  posted_at: "Posté le",
  location: "Localisation",
  category: "Catégorie",
  job_offer: "Offre d'emploi",
  test_link: "Lien du test",

  captchaError: "Erreur CAPTCHA. Veuillez vérifier à nouveau.",
  errorCarrier: "Erreur du transporteur",
  infoSecure: "Vos informations sont en securite avec nous",
  noAccount: "Pas de compte?",
  noReceiver: "Compte {{operator}} inexistant",
  networkSignalIssue:
    "Le signal réseau {{network}} connaît des perturbations par conséquent si vous poursuivez, votre transaction pourrait prendre plus de temps à être finalisée",
  recipientLimit: "Limite de destinataires dépassée",
  welcomeMessage: "Nous sommes Heureux de vous revoir",
  wrongName: "Mauvais nom",

  all: "Tous les destinataires",

  careers: "Carrière",

  requiedFName: "Requis",
  requiedEmail: "Le champ Email est Requis",
  requiedCity: "Requis",
  requiedMessage: "Requis",
  requiedContry: "Requis",
  other: "Autre",
  passwordSizeError:
    "Taille du mot de passe incorrecte : minimum 8 caractères.",
  next: "Suivant",
  privacyPolicy: "Politique de confidentialité",
  confirmPassword: "Confirmer le mot de passe",
  dowload: "Scannez le code avec votre téléphone pour obtenir l'application",
};

export default globalLang;
