/**
 * mx FR translations
 */
const mx = {
    "end_waiting": "Veuillez patienter jusqu'à la fin de l'opération.",
    "error_occurred": "Une erreur s'est produite. Veuillez réessayer plus tard.",
    "contact_us": "Si le problème persiste, veuillez nous contacter",
    "success_add_bank": " La banque a été ajoutée avec succès.",
    "deposit_next_step": "Pour effectuer un dépôt, suivez le bouton suivant ou rendez-vous sur votre tableau de bord dans l'onglet wallet pour effectuer un dépôt",
    "back_to_wallet": "Retour au portefeuille",
}

export default mx;
