/**
 * Home EN translations
 */
const home = {
    "main": "Transfergratis, The safe, easy and Fast way to Transfer money with No fees.",
    "subMain": "With Transfergratis, transfer money for FREE to your loved ones in Cameroon in minutes.",
    "secondTitle": "We are ready to serve your needs",
    "thirdTitle": "EASY AS 1.2.3",
    "testimonyTitle": "What our customers are saying",
    "services": {
        "title1": "Easy to use",
        "description1": "Transfergratis is designed to make it easy to send money with just a few clicks. Wherever you are, from your phone, tablet or computer.",
        "title2": "Fast payments",
        "description2": "The entire Transfergratis system exists and is optimized to allow you to transfer money very quickly.",
        "title3": "Free of charge",
        "description3": "The Transfergratis service is completely free, nothing more than the exact amount you want to transfer will be charged.",
        "title4": "100% secure",
        "description4": "Transfergratis is licensed as a Money Services Business, which means that we are authorized by the Canadian Government."
    },
    "sendMoney": "Send money",
    "receivesFunds": "The recipient receives funds"

}

export default home;
