import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosSign } from "../../../paramters";

/**
 * POST - ExtraReducer call api fetching authenticated user transactions list
 */
export const fetchPageTransactions = createAsyncThunk(
  "navigation/fetchPageTransactions",
  async (user_id) => {
    return axiosSign
      .post("/api/mytransactions", { user_id })
      .then((res) => res.data);
  }
);

/**
 * GET - Request to get all data constant
 */
export const fetchConstants = createAsyncThunk(
  "navigation/fetchConstants",
  async () => {
    return axiosSign.get("/api/allConstants").then((res) => res.data);
  }
);

/**
 * POST - ExtraReducer call api fetching authenticated user recipients list
 */
export const fetchUserRecipient = createAsyncThunk(
  "user/fetchUserRecipient",
  async (data) => {
    return axiosSign.post("/api/myrecipients", data).then((res) => res.data);
  }
);

/**
 * POST - ExtraReducer call api performing user new transaction
 */
export const pushNewTransaction = createAsyncThunk(
  "user/pushNewTransaction",
  async (data) => {
    return axiosSign
      .post("/api/createTransaction", data)
      .then((res) =>
        typeof res.data.get_solde === "undefined" ? res.data[0] : res.data
      );
  }
);

/**
 * GET - ExtraReducer call api fetching job offers
 */
export const fetchJobOffer = createAsyncThunk(
  "user/fetchJobOffer",
  async (query = "") => {
    return axiosSign.get("/api/job_offers?" + query).then((res) => res.data);
  }
);

/**
 * POST - ExtraReducer call api save Contact form data
 */
export const saveContact = createAsyncThunk(
  "user/saveContact",
  async (data) => {
    console.log(data, "send");
    return axiosSign.post("/api/saveContactForm", data).then((res) => res.data);
  }
);

/**
 *
 * GET - ExtraReducer call api Get Online Payement Status
 *
 */
export const getOnlinePayementStatus = createAsyncThunk(
  "all/getOnlinePayement",
  async () => {
    return axiosSign.get("/api/getButtonStatus").then((res) => res.data);
  }
);
