import axios from "redaxios";

export const routeBase = "";
// export const apiBase = process.env.REACT_APP_BASE_LOCAL_API;
export const apiBase = process.env.REACT_APP_BASE_API;

export const noAuthRedirectTo = routeBase + "/login";

export const authRedirectTo = routeBase + "/faqs";

/**
 * Request signature
 */
// eslint-disable-next-line no-undef
// export const requestSign = process.env.REACT_APP_LOCAL_API_KEY;
export const requestSign = process.env.REACT_APP_API_KEY;

// default axios header
export const defaultHeader = { headers: { signature: requestSign } };

/**
 * Custom axios instance
 *
 * @type {axios.AxiosInstance}
 */
export const axiosSign = axios.create({
  baseURL: apiBase,
  headers: {
    "Access-Control-Allow-Origin": "*",
    // 'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    signature: requestSign,
  },
  withCredentials: false,
});

/**
 * All app route whose are not under authentication restriction
 *
 * Each route are double to avoid potential url error
 *
 * @type {(string|string)[]}
 */
export const noneAuthRoute = [
  routeBase,
  routeBase + "/",
  routeBase + "/send",
  routeBase + "/send/",
  routeBase + "/contact_us",
  routeBase + "/contact_us/",
  routeBase + "/faqs",
  routeBase + "/faqs/",
  routeBase + "/about_us",
  routeBase + "/about_us/",
  routeBase + "/resetPassword",
  routeBase + "/resetPassword/",
  routeBase + "/resetPasswords",
  routeBase + "/resetPasswords/",
  routeBase + "/login",
  routeBase + "/login/",
  routeBase + "/register",
  routeBase + "/register/",
  routeBase + "/transfer/init",
  routeBase + "/transfer/init/",
  routeBase + "/connectMX",
  routeBase + "/connectMX/",
  routeBase + "/conditions_terms",
  routeBase + "/conditions_terms/",
  routeBase + "/conditions_terms/ca",
  routeBase + "/conditions_terms/usa",
  routeBase + "/policy",
  routeBase + "/policy/",
  routeBase + "/cookies",
  routeBase + "/cookies/",
  routeBase + "/career",
  routeBase + "/career/",
  routeBase + "/career/jobOffer/",
  routeBase + "/career/jobOffer",
];

/**
 * list for the register page
 *
 * @type {string[]}
 */
export const howKnow = [
  "Facebook",
  "Google",
  "Snapchat",
  "Tiktok",
  "Instagram",
  "global.friend",
];

export const CLIENT_COUNTRIES = [
  {
    id: 1,
    code: "CA",
    name_fr: "Canada",
    name_en: "Canada",
    flag: "🇨🇦",
  },
  {
    id: 2,
    code: "US",
    name_fr: "États-Unis",
    name_en: "United States",
    flag: "🇺🇸",
  },
];
