/**
 * About us FR translations
 */
const aboutUs = {
    "p1": "Chez Transfergratis, nous croyons qu'un transfert d'argent est plus qu'une simple transaction.  C'est un enfant qui pourra aller à l'école, c'est une famille qui devra manger, c'est un malade dont la vie sera sauvée.",
    "p2": "Tout ce bien est fait par ceux comme vous qui ont fait l'énorme sacrifice de laisser leur familles pour aller étudier et travailler dans un autre pays pour prendre soin de ceux qu’ils aiment et pour contribuer au développement de leurs pays. ",
    "p3": "C’est pour cette raison que toute l'équipe OneTransfer est engagée à offrir les meilleurs services gratuit de transfert de fonds, Afin de nous aussi contribuer au bien que vous faites. ",
    "p4": "Transfergratis is a company registered in Canada under company number 1301769-9, registered with FINTRAC under no. 21138662.",
}

export default aboutUs;