const recusTrasaction = {
  title: "Transaction Receipts",
  subtitle: "Transaction Complete with Success",
  message:
    "For an accurate, fast and free online money transfer, I recommend TransferGratis",
  destitnation: "Recipient",
  numCompte: "Numero de compte",
  transId: "Transaction ID",
  method: "Method",
  montant: "Montant transféré",
  montantTotalRecus: "amount received",
  montantTotal: "total amount",
  tipAmout: "Tip Amount",
  frais: "TransferGratis Fees",
};

const ReleverTransaction = {
  title: "Transaction Record",
  copnum: "corporation number :",
  expediteur: "Name Expediter",
  montant: "Amount (CAD)",
  destinateur: "Recipient Name",
  numCompte: "Account number",
  motif: "Purpose",
  tip: "Tip",
  total: "Total",
};
const pdf = {
  recusTrans: recusTrasaction,
  releveTrans: ReleverTransaction,
};

export default pdf;
