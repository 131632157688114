/**
 * All app lang keys to display in header menu
 *
 * @type {[{flag: string, title: string, key: string},{flag: string, title: string, key: string}]}
 */
const langKeys = [
    {
        key: 'fr',
        title: '',
        flag: '/images/flags/fr.svg'
    },
    {
        key: 'en',
        title: '',
        flag: '/images/flags/us.svg'
    }
];
export default  langKeys;
