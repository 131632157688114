/**
 * Contact us FR translations
 */
const faqs = {
  faq1: {
    title: "Quels services propose Transfergratis ?",
    content:
      "Transfergratis propose le transfert d'argent du Canada vers le Cameroun avec les méthodes de dépôt suivantes :",
    other: {
      "item-1": "Virement bancaire",
      "item-2": "Mobile money",
    },
  },
  faq2: {
    title: "Comment puis-je payer mon transfert ?",
    content: "Nous acceptons pour méthode de paiement: Interac",
  },
  faq3: {
    title: "Où puis-je envoyer de l'argent ?",
    content:
      "Transfergratis propose des transferts d'argent vers le Cameroun,la Côte d'Ivoire, le Togo, le Burkina Faso, l'Éthiopie et le Bénin.",
  },
  faq4: {
    title: "D'où puis-je envoyer de l'argent ?",
    content:
      'Transfergratis est disponible pour les expéditeurs au Canada. Votre "pays d\'envoi" est le pays dans lequel vous résidez lorsque vous créez votre compte.',
  },
  faq5: {
    title: "Quel est le montant minimum que je peux envoyer ?",
    content:
      "Le montant minimum d'argent que vous pouvez envoyer est de 15$ CAD pour le dépôt d'argent mobile money et de 1000$ CAD pour les virements banquaires.",
  },
  faq6: {
    title: "Quels sont vos frais de service ?",
    content:
      "Transfergratis propose un transfert d'argent rapide et sécurisé du Canada vers le Cameroun, TOTALEMENT gratuitement.",
  },
  faq7: {
    title: "Combien de temps faudra-t-il pour que l'argent arrive?",
    content:
      "Dans la plupart des cas, les fonds envoyés en arrivent en quelques minutes pour le transfert d'argent mobile mobile money (Orange money et MTN mobile money) et en moins d'un jour ouvrable pour le dépôt bancaire. Nous visons à effectuer les transferts dans les délais suggérés, mais certains transferts peuvent prendre plus de temps en fonction de la manière dont l'argent est reçu. Votre transfert ne commencera pas tant que Transfergratis n'aura pas reçu les fonds venant de votre fournisseur de paiement. Les contrôles d'identité de l'expéditeur et/ou du destinataire, les heures d'ouverture de tiers ou des informations incorrectes peuvent également prolonger le temps de transfert." +
      " Type de transfert généralement disponible pour le destinataire :",
    other: {
      "item-1-title": "Virement bancaire:",
      "item-1":
        "En moins d'un jour dans les banques sélectionnées. Les horaires peuvent également dépendre de : La banque, le Jour de la semaine, l'heure de création de la transaction",
      "item-2-title": "Mobile money (Orange Money / MTN Mobile Money):",
      "item-2": "En quelques minutes",
    },
  },
  faq8: {
    title: "Comment mon argent est-il protégé ?",
    content:
      "Transfergratis est autorisé en tant qu'entreprise de services monétaires, ce qui signifie que nous sommes autorisés à offrir à nos clients certains services financiers, tels que le transfert d'argent. Nous sommes une entreprise enregistrée au Canada sous le numéro de société 1301769-9, enregistrée auprès de CANAFE sous le no. 21138662.",
  },
  faq9: {
    title:
      "Pourquoi avez-vous besoin d'un numéro de téléphone portable et d'une adresse e-mail pour mon destinataire?",
    content:
      "Nous utilisons le numéro de téléphone portable et l'adresse e-mail du destinataire pour l'informer de la progression du transfert et pour effectuer le dépôt mobile money avec le numéro de téléphone si nécessaire. La fourniture de l'adresse e-mail du destinataire est facultative. L'expéditeur et le destinataire sont avertis lorsque le transfert est terminé.",
  },
  faq10: {
    title: "Pourquoi dois-je fournir une pièce d'identité ?",
    content:
      "Nous sommes tenus par la loi d'effectuer ces vérifications. Pour assurer la sécurité du service Transfergratis, nous utilisons une pièce d'identité avec photo émise par le gouvernement pour confirmer l'identité de l'expéditeur de l'argent.",
  },
  faq11: {
    title:
      "De quelles informations ai-je besoin de mon destinataire pour les virements bancaires vers le Cameroun?",
    other: {
      "item-1":
        "Nom complet. Pour éviter tout retard, le nom du destinataire doit correspondre exactement au nom figurant sur son relevé bancaire.",
      "item-2":
        "Détails du compte. Nous avons besoin du (1) nom de la succursale, (2) du code de la banque, (2) du code de la succursale et (4) du numéro de compte de votre bénéficiaire, généralement de 12 à 14 chiffres.",
      "item-3":
        "Motif d'envoi . Pour indiquer s'il s'agit de « soutien de la famille ou d'amis », « l'achat de services », « le paiement de la propriété » ou « l'envoi de fonds à vous-même ».",
      "item-4":
        "Courriel (facultatif). Nous l'utilisons pour envoyer un e-mail à votre destinataire avec les détails de la transaction.",
      "item-5":
        "Veuillez vous assurer que tous les détails de votre destinataire sont corrects. Une fois que vous avez effectué votre transaction, il nous est difficile de modifier ou d'arrêter le transfert, même si nous ferons toujours de notre mieux pour le faire. ",
    },
  },
  vb: "Virement bancaire",
  mm: "Mobile money.",
};

export default faqs;
