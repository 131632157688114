import aboutUs from "./about_us";
import contactUs from "./contact_us";
import errorPage from "./errorPage";
import faqs from "./faqs";
import footer from "./footer";
import globalLang from "./global";
import home from "./home";
import kyc from "./kyc";
import message from "./message";
import meta from "./meta";
import transaction from "./transaction";
import transfer from "./transfer";
import mx from "./mx";
import sharePage from "./sharePage";
import resetPage from "./resetPage";
import referral from "./referral";
import pdf from "./pdf";
import link from "./link";
import register from "./registration";

/**
 * All FR lang data
 */
const langFr = {
  fr: {
    translation: {
      contact_us: contactUs,
      about_us: aboutUs,
      errorPage: errorPage,
      faqs: faqs,
      footer: footer,
      global: globalLang,
      home: home,
      sharePage: sharePage,
      resetPage: resetPage,
      transaction: transaction,
      transfer: transfer,
      kyc: kyc,
      meta: meta,
      message: message,
      mx: mx,
      referral: referral,
      pdf: pdf,
      link: link,

      profileModalSub:
        "Toutes fois, il vous est possible de nous contacter afin de nous soumettre votre probleme via les canal disponible en suivant le lien ",
      profileModalTle:
        "Pour des raisons de securité, il ne vous est plus possible d'editer votre profile apres avoir completé le KYC.",

      "The email has already been taken.": "Email déjà utilisé.",
      "The password must be at least 8 characters.":
        "Minimum 8 caractères requis",
      "Signial error message":
        "Le réseau est perturbé, veuillez réessayer avec un autre moyen de paiement!",
      "Withdrawal methods available": "Méthodes de retrait disponibles",
      // "InformationErrorMessage" : "Assurez vous que les informations entrées sont valide..",
      InformationErrorMessage:
        "Dear customer, to secure your transfers, please validate the information in your profile.This ensures the safety and efficiency of your transactions.",

      sendMail: "Envoyer le lien de réinitialisation du mot de passe",
      sendedMail: "Email envoyé.",
      errorMail: "Une erreur c'est produite. Veuillez réessayer plus tard.",
      close: "Fermer",
      titleMail: "Réinitialiser le mot de passe.",
      register: register,
    },
  },
};

export default langFr;
