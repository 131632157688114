/**
 * Contact Us EN translations
 */
const faqs = {
  faq1: {
    title: "What services does Transfergratis offer?",
    content:
      "Transfergratis proposes the transfer of money from Canada to Cameroon with the following deposit methods:",
    other: {
      "item-1": "Bank transfer",
      "item-2": "Mobile money",
    },
  },
  faq2: {
    title: "How can I pay for my transfer?",
    content: "We accept for payment method: Interac",
  },
  faq3: {
    title: "Where can I send money?",
    content:
      "Transfergratis offers money transfers to Cameroon, Ivory Coast, Togo, Burkina Faso, Ethiopia and Benin.",
  },
  faq4: {
    title: "Where can I send money from?",
    content:
      'Transfergratis is available to shippers in Canada. Your "sending country" is the country you live in when you create your account.',
  },
  faq5: {
    title: "What is the minimum amount I can send?",
    content:
      "The minimum amount of money you can send is $15 CAD for mobile money deposits and $1,000 CAD for bank transfers.",
  },
  faq6: {
    title: "What are your service charges?",
    content:
      "Transfergratis offers a fast and secure money transfer from Canada to Cameroon, TOTALLY free.",
  },
  faq7: {
    title: "How long will it take for the money to arrive?",
    content:
      "In most cases, the funds sent arrive in a few minutes for mobile money transfer (Orange money and MTN mobile money) and in less than one business day for bank deposit. Our goal is to complete transfers within the suggested timeframe, but some transfers may take longer depending on how the money is received. Your transfer will not begin until Transfergratis receives the funds from your payment provider. Sender and/or recipient identity checks, third-party opening hours or incorrect information can also extend the transfer time." +
      " Type of transfer generally available for the recipient:",
    other: {
      "item-1-title": "Bank transfer",
      "item-1":
        "In less than one day in the selected banks. Timetables can also depend on: The bank, the Day of the week, the time of creation of the transaction",
      "item-2-title": "Mobile money (Orange Money / MTN Mobile Money):",
      "item-2": "In a few minutes",
    },
  },
  faq8: {
    title: "How is my money protected?",
    content:
      "Transfergratis is authorized as a money services business, which means that we are authorized to offer our clients certain financial services, such as money transfer. We are a company registered in Canada under company number 1301769-9, registered with FINTRAC under no. 21138662.",
  },
  faq9: {
    title:
      "Why do you need a mobile phone number and email address for my recipient?",
    content:
      "We use the recipient’s mobile phone number and email address to inform them of the progress of the transfer and to deposit mobile money with the phone number if necessary. Providing the recipient’s email address is optional. The sender and recipient are notified when the transfer is complete.",
  },
  faq10: {
    title: "Why do I need to provide identification?",
    content:
      "We are required by law to carry out these checks. To ensure the security of Transfergratis, we use government-issued photo ID to confirm the identity of the sender of the money.",
  },
  faq11: {
    title:
      "What information do I need from my recipient for bank transfers to Cameroon?",
    other: {
      "item-1":
        "Full name. To avoid any delay, the recipient’s name must match exactly the name on his bank statement.",
      "item-2":
        "Account details. We need the (1) name of the branch, (2) bank code, (2) branch code and (4) your beneficiary’s account number, typically 12-14 digits.",
      "item-3":
        "Reason for sending . To indicate whether it is “family or friend support”, “purchase of services”, “payment for the property” or “sending funds to yourself”. ",
      "item-4":
        "Email (optional). We use it to send an email to your recipient with the details of the transaction.",
      "item-5":
        "Please ensure that all details of your recipient are correct. Once you’ve completed your transaction, it’s hard for us to change or stop the transfer, although we’ll always do our best to do so.",
    },
  },
  vb: "Bank transfer",
  mm: "Mobile money.",
};

export default faqs;
