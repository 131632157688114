/**
 * Transaction EN translations
 */
const transaction = {
  transactions: "Transactions",
  profile: "Profile",
  security: "Security",
  send_via: "Send via",
  showing: "Showing",

  amount_transferred: "Amount transferred",
  tip_amount: "Tip Amount",
  beneficiary: "Beneficiary",
  changePasswordError: "Incorrect current password.",
  changePasswordSuccessful: "Password updated.",
  corfirmationNewPassword: "Corfirm new password",
  confirmPassword: "Confirm password",
  costs: "Costs",
  date: "Date",
  newPassword: "New password",
  newPasswordDifferent: "The new password must be different from the old one",
  oldPassword: "Old password",
  personal: "Détails personnel",
  shipper: "Shipper",
  total_amount: "Total Amount",
  wallet: "Wallet",
  bank: "Bank",
  invite: "invite",
};

export default transaction;
