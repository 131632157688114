import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const SupportContact = () => {
  return (
    <div className="wha-icon">
      <a
        href="https://wa.me/15146657518?text=Bonjour%2C%0AJ%27ai+besoin+d%27assistance."
        target="_blank"
        rel="noopener noreferrer"
      >
        <LazyLoadImage src="/images/v2/wIcon.webp" alt="Whatsapp icon" />
      </a>
    </div>
  );
};

export default SupportContact;
