import langFr from "./fr/fr";
import langEn from "./en/en";

/**
 * Resources of multi-langage translations
 */
const resources = {
    ...langFr,
    ...langEn
}

export default resources;