/**
 * Home FR translations
 */
const home = {
    "main": "Transfergratis, le moyen sûr, facile et rapide de transférer de l'argent.",
    "subMain": "Avec Transfergratis, transférez de l'argent GRATUITEMENT à vos proches au Cameroun en quelques minutes.",
    "secondTitle": "Nous sommes prêts pour servir vos besoins",
    "thirdTitle": "C'EST DE LA TARTE",
    "testimonyTitle": "Ce que disent nos clients",
    "services": {
        "title1": "Facile à utiliser",
        "description1": "Transfergratis est conçu pour faciliter l’envoi d’argent en quelques clics. Où que vous soyez, depuis votre téléphone, votre tablette ou votre ordinateur.",
        "title2": "Paiements rapides",
        "description2": "L’ensemble du système Transfergratis existe et est optimisé pour vous permettre de transférer de l’argent très rapidement.",
        "title3": "Gratuit",
        "description3": "Le service Transfergratis est entièrement gratuit, rien de plus que le montant exact que vous souhaitez transférer ne sera facturé.",
        "title4": "100% sécurisé",
        "description4": "Transfergratis est agréé en tant qu’entreprise de services monétaires, ce qui signifie que nous sommes autorisés par le gouvernement canadien."
    },
    "sendMoney": "Envoyer de l'argent",
    "receivesFunds": "Le destinataire reçoit des fonds"
}

export default home;