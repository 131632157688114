import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./features/user/userSlice";
import navigationReducer from './features/navigation/navigationSlice'
import { fetchCheckUser } from "./features/user/userAction";

/**
 * Redux store
 */
const store = configureStore({
    reducer: {
        user: userReducer,
        navigation: navigationReducer
    }
});

// initial user check on app open
store.dispatch(fetchCheckUser())

export default store;
