import React from "react";
import "./styles.scss";
import { PropTypes } from "prop-types";
import { useSelector } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";
/**
 * The footer component layout
 *
 * @returns {JSX.Element}
 */
const CookiesComponent = ({ setCookiesChange }) => {
  const createItem = () => {
    localStorage.setItem("cookies5", "concent");
    setCookiesChange("cookies4");
  };
  const lang = useSelector((state) => state.user.lang);

  return (
    <div className="container">
      <div className="content-content">
        <div className="left-container">
          <LazyLoadImage src="/images/v2/cookies.png" alt="cookies" />
          <p className="content">
            {lang === "fr"
              ? "En poursuivant votre navigation, vous acceptez l'utilisation des cookies de notre site web comme indiqué dans "
              : "By continuing to browse, you accept the use of cookies of our website as mentioned in "}

            <a href="/cookies">
              {lang === "fr"
                ? "notre politique de confidentialité."
                : "our privacy policy."}
            </a>
          </p>
        </div>
        <button className="v2-btn-green-contact_us" onClick={createItem}>
          {lang === "en" ? "I Understand" : "Je Comprends"}
        </button>
      </div>
    </div>
  );
};
CookiesComponent.propTypes = {
  setCookiesChange: PropTypes.func.isRequired,
};
// style={{ color: "#5eba6a" }}
export default CookiesComponent;
