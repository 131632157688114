const register = {
  step0: {
    title: "Register",
    info: "Already have an account?",
    link: "Login",
    info2: "Or Sign Up with",
    aceptText:
      "I confirm that i have read and accepted the terms and conditions.",
  },
  step1: {
    title: "How to contact you ?",
  },
  step2: {
    title: "Tell us more about yourself",
  },
  step3: {
    title: "Choose your best password",
  },
};
export default register;
