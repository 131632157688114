/**
 * Error Page EN translations
 */
const errorPage = {
    "main": "We're sorry, there was an error, please return to the transfergratis homepage.",
    "button": "Back to home",
    "messages": {
        "email": "Incorrect email",
        "phone": "Invalid phone format",
        "account": "Invalid account number format",
        "postal": "Invalid postal code format",
        "phoneConfirm": "Not match",
        "password": "Invalid format (must contain letter(s), digit(s), special character(s))",
        "passwordLength": "Minimum 8 characters",
        "confirmPassword": "Invalid confirm password format",
        "passwordMismatch": "Mismatch confirm password",
        "save": "An error occured. please try again later.",
        "dataLoad": "An error occurred while loading your information. Please refresh the page and if the problem persists, please contact us.",
        "dataSend": "An error occurred while executing your operation. Please refresh the page and if the problem persists, please contact us.",
        "COUNTRY_NOT_MATCH": "The number entered does not correspond to any operator in the country of the transaction"
    }
}

export default errorPage;
