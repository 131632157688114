/**
 * mx EN translations
 */
const mx = {
    "end_waiting": "Please wait until the operation end.",
    "error_occurred": "An error occurred. please try again later.",
    "contact_us": "If the problem persists, please contact us",
    "success_add_bank": " The bank was added successfully.",
    "deposit_next_step": "To make a deposit, follow the following button or go to your dashboard in the wallet tab to make a deposit",
    "back_to_wallet": "Back to wallet",

}

export default mx;
