/**
 * Error Page FR translations
 */
const errorPage = {
    "main": "Nous sommes désolés, il ya eu une erreur, veuillez s'il vous plait retourner à l'acceuil transfergratis. ",
    "button": "Retour à la page d'accueil",
    "messages": {
        "email": "Email incorrect",
        "phone": "Format du téléphone invalide",
        "account": "Format du numéro de compte invalide",
        "postal": "Format du code postal invalide",
        "phoneConfirm": "Ne correspond pas",
        "password": "Format invalide(doit contenir lettre(s), chiffre(s), caractère(s) spécial)",
        "passwordLength": "Minimum 8 caractères",
        "confirmPassword": "Format invalide",
        "passwordMismatch": "Mot de passe pas identique",
        "save": "Une erreur c'est produite. Veuillez réessayer plus tard.",
        "dataLoad": "Une erreur s'est produite lors du chargement de vos informations. Veuillez rafraîchir la page et si le problème persiste, veillez, nous contacter.",
        "dataSend": "Une erreur s'est produite lors de l'execution de votre operation. Veuillez rafraîchir la page et si le problème persiste, veillez, nous contacter.",
        "COUNTRY_NOT_MATCH": "Le numero saisi ne correspond a aucun operateur du pays de la transaction"
    }
}

export default errorPage;
