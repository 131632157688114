import React from "react";

const Icon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 445 442">
      <g className="Rocket">
        <path
          fill="#2EBD60"
          d="M211.34 304.51v-21.82a4.108 4.108 0 00-1.32-3.22 18757.473 18757.473 0 01-47.41-47.42 5.352 5.352 0 00-4.19-1.65c-14.48.07-29 .06-43.43 0-1 0-2.7.38-1.62-1.71 4.72-9 8.72-18.45 14.06-27.16 4.22-6.87 9.42-12.83 16.48-17a31.335 31.335 0 0115.6-4.37c8.57-.15 17.14-.13 25.71-.08a4.906 4.906 0 004.36-2.09A183.192 183.192 0 01253.85 126a135.14 135.14 0 0135.25-11.7 82.032 82.032 0 0115.26-1.16h22.25c1.82 0 2.25.55 2.22 2.32-.11 7.35-.11 14.7 0 22 .11 11.07-2.15 21.71-5.56 32.15-7 21.28-18.32 40-32.74 57a183.804 183.804 0 01-27.31 26.19c-.465.32-.839.756-1.084 1.265a3.239 3.239 0 00-.316 1.635c0 7.78-.14 15.56 0 23.34.26 12.6-4.95 22.48-14.52 30.34-6.67 5.48-14.33 9.25-22 13a1245.116 1245.116 0 00-11.59 5.72c-2.35 1.16-2.35 1.17-2.36-1.54v-22.05h-.01zM245 179.89a16.807 16.807 0 0019.98 16.777 16.812 16.812 0 0012.339-22.988 16.814 16.814 0 00-15.669-10.329A16.63 16.63 0 00245 179.89z"
          className="Engine"
        ></path>
        <path
          fill="#2EBD60"
          d="M128.74 314.28c-.84 0-1.39-.08-1.16-1.22 2.49-12.37 5.67-24.49 12.47-35.34 3.13-5 7.78-8.6 11.71-12.87 1.07-1.16 1.63-.15 2.24.45 6.113 6.1 12.21 12.21 18.29 18.33 1.48 1.48 3 2.93 4.46 4.39.58.58 1.16 1.18.3 2-5.17 4.85-9.69 10.44-16.07 13.91a88.623 88.623 0 01-25.7 9c-2.18.45-4.36.91-6.54 1.35z"
          className="Fire"
        ></path>
      </g>
      <circle
        cx="220.94"
        cy="220.94"
        r="207.6"
        stroke="#001D0A"
        strokeWidth="16"
        className="Circle"
      ></circle>
    </svg>
  );
};

export default Icon;
