/**
 * Message FR translations
 */
const message = {
  presence: "^*Le champ {{label}} est requis",
  invalidField: "^*Le champ {{label}} est invalide",
  stringOnly: "^*Le champ {{label}} ne peut contenir que des lettres",
  passwordRequirements:
    "^*Le mot de passe doit comporter au moins 8 caractères",
  emailDuplicate: "^*Cette adresse mail est déjà utilisée",
  anyError: "Une erreur est survenue",
  reasonOfSending: "Vous devez choisir une raison de transfer",
  fname: "Vous devez renseigner le prenom",
  lname: "Vous devez renseigner le nom",
  email: "Vous devez renseigner l'adresse mail",
  phone: "Vous devez renseigner le numero de téléphone",
  dob: "Vous devez renseigner la date de naissance",
  password: "Vous devez renseigner le mode de passe",
  confirmPassword: "Vous devez renseigner la confirmation du mode de passe",
  occupation: "Vous devez renseigner l'occupation",
  howHear: "Vous devez renseigner Comment vous avez entendu parler de nous",
  postalCode: "Vous devez renseigner le code postal",
  gender: "Vous devez renseigner le genre",
  captchatResponse: "Vous devez valider le captchat",
  province: "Vous devez renseigner la province",
  town: "Vous devez renseigner la ville",
  address: "Vous devez renseigner l'adresse",
  accountNumber: "Vous devez renseigner le numero de compte",
  confirmAccountNumber:
    "Vous devez renseigner la confirmation du numero de compte",
  kyc_verifying: "Vérification et validation de vos informations personnelles.",
  kyc_waiting: "Merci de patienter quelques instants..",
  kyc_info_to_update:
    "Il y a quelques informations à mettre à jour avant de commencer à transférer de l'argent",
  kuc_completed_verify: "Votre vérification est terminée.",
  postulated: "Votre candidature a été envoyer.",
  test_advice:
    "Pour cette offre d'emploi, vous devez passer le test en suivant le lien ci-dessous",
  for_other: {
    thirdPartyAddress: "Vous devez renseigner l'adresse du tiers",
    thirdPartyRelation:
      "Vous devez renseigner la relation entre le tiers et vous",
    thirdPartyProfesionOrNature:
      "Vous devez renseigner la profession ou nature de l'activité du tiers",
    thirdPartyPhone: "Vous devez renseigner la téléphone du tiers",
    thirdPartyDob_thirdPartyNumber_none:
      "Vous devez renseigner soit une Date de naissance du tiers, soit un Numéro de constitution / d’enregistrement et la juridiction de constitution / enregistrement (si le tiers est une entité)",
    thirdPartyDob_thirdPartyNumber_defined:
      "Vous ne devez pas renseigner a la fois une Date de naissance du tiers et Numéro de constitution / d’enregistrement et la juridiction de constitution / enregistrement (si le tiers est une entité)",
  },
  transfer_notice: {
    title: "Important : Vérifiez le numéro de compte !",
    description:
      "Assurez-vous que le Numéro de compte du destinataire est correct. TransferGratis n'est pas responsable des fonds envoyés à un mauvais numéro.",
  },
  announcement: {
    new_ci:
      "🇨🇮Transfergratis offre maintenant des transferts gratuits et au taux du jour vers la Côte d'Ivoire ! Envoyez de l'argent dès aujourd'hui 🇨🇮",
    new_ng:
      "Transfergratis offre désormais des transferts gratuits et aux meilleurs taux vers le Nigeria ! Envoyez de l'argent dès maintenant !",
  },
  readMore: "Lire la suite",
};

export default message;
