/**
 * Transfer EN translations
 */
const transfer = {
  main: "Send money",
  subMain:
    "With Transfergratis, transfer money for FREE to your loved ones in Cameroon and other in minutes.",
  reasonsOfSending: "Reasons of sending",
  bankName: "Bank name",
  branchName: "Branch name",
  agencyCode: "Agency code",
  accountNumber: "Account number for {{network}} network:",
  accountNumberBank: "Account number ",
  accountLastNumber: "Account last Number",
  confirmAccountNumber: "Confirm account number",
  amountSend: "Amount send",
  amountSendTotal: "Total amount",
  amountTip: "Amount of The Tip",
  leaveTip: "Leave a tip",
  forOther: "Check if you are doing this transaction on behalf of someone else",
  coupon_notice:
    "You have a coupon of ${{amount}} which will be applied to your next transfer",
  done: {
    firstTextP: "Transaction recorded, please make the INTERAC transfer",
    firstTextP2:
      "Sorry, an error occurred with the online payment, please do the INTERAC transfer manually.",
    payAmount: "Pay ${{amount}} CAD online here",
    proccedePaiement: "proceed to the payment",
    boldText:
      "If your bank account is Desjardins or RBC, or if you are unable to pay online using the button above, send ${{amount}} via Interac to",
    boldText2: "Send ${{amount}} via Interac at the address ",
    secondTextP:
      "Our address is configured for automatic deposit. However, if you are prompted for a security question and answer, please enter:",
    needHelp: "Need help?",
    lastTextP:
      "NB: The name of the bank account issuing the Interac transfer must match the name of your account on transfergratis.com. Otherwise, the transaction cannot be executed.",
    usa: {
      transfer_done_title: "your transfer has been initiated.",
      transfer_done_detail: "For more information, go on your dashboard.",
      transfer_fail_title: "Your transfer can not be completed.",
      transfer_fail_detail: "You have to add funds in your wallet.",
      to_wallet: "Go to wallet",
    },
  },
  deposit: {
    title:
      "To fund your account, select your account, enter amount below and click the PAY button",
    conformation_thanks:
      "Thank you for your deposit. The confirmation below verifies that you have",
    payment_number: "Payment Confirmation Number",
    payment_account: "Authorized Payment Amount",
    date_authorized: "Date Authorized",
    expected_date: "Expected Payment Date",
    add_bank: "Add a bank",
    update_kyc:
      "You have to update your personal information to valid your KYC and obtain a wallet",
  },
  nameFormatError: "Accents and special characters not allowed",
  thirdParty: {
    address: "Third party address",
    relation: "Relation between the third party and you",
    profesionOrNature:
      "Profession or nature of the business of the third party",
    phone: "Third Party Phone",
    dob: "Date of birth of the third party (if it is an individual)",
    number:
      "Incorporation/registration number and jurisdiction of incorporation/registration (if the third party is an entity)",
    wave_redirect: "In case of network interruption send with Wave",
  },
  dialog: {
    title: "Select Your Payment Option",
    desJardinsMsg: "DesJardins Bank",
    otherBank: "Another Bank",
    tipTitle: "Thank you very much for your tip {{amount}} CAD! 🎉",
    tipBody:
      "We are deeply grateful for your generosity. As you know, Transfergratis is a free service so your contribution will directly help improve and develop our services to offer you an even better experience.",
    witTipAction: "Send with ${{amount}} of Tip",
    withoutTipAction: "Send Without Tip",
  },
};

export default transfer;
