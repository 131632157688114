/**
 * Contact Us EN translations
 */
const kyc = {
  "title": "Verifying your identity",
  "title-2": "Re-verification of your identity",
  "p1": "For security reasons, we need to validate your identity.",
  "p1-2": "For security reasons, we need to validate your identity again, because the previous identification has expired.",
  "p2": "For this you will need: ",
  "l1": "A Photo ID;",
  "l2": "A selfie of you.",
  "needHelp": "Need help?",
  "start": "Start"
}

export default kyc;